import { default as energy_45green_45practicesCZaknKSkn7Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeycdL8TLQ5WUMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderIttqkvtSSEMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexojAoldV5TtMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teamuNUBZtzEk0Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderVuPaDR4SDoMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcessDkrfcq84QMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93wFkhlmkBnCMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexfLTZWQJWRAMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexjsGgiI3yDhMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93rxUytysiW5Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexrQMB8pUNBrMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as ask_45a_45questionZO0RTrz5xbMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitieseOXzvNGBCTMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqwyMo3JLwclMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesKOrd1gyOd5Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexb4lpwYuC38Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationszzV5NsdxWTMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersdHCZOKb4g1Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexmnEv4sirG8Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexfAXqD74brHMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexBXWJW4b1T3Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexd643VYbO80Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexg2aWzJMjX2Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexkoBlcWj6tNMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexdBlsJNjD8UMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationbTWxI1pexQMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionsYHB4CYgahMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placePapiIGKbECMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceIL1UTjvtkmMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93cmqbCFNE6nMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexHyMTWFA5meMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexuNYsPRYHRSMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessdkY9MhbjjWMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93FTInoVadl0Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoritesyjL2qF0KVAMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchYdJVFEUeTWMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhouseEv9DyKPdWGMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionXjKC5bn4XlMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageF8Yl93YN9hMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexZ394JRFFLfMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsMej0sZ9Ox9Meta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usuu9GkMKtLpMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsgyLR7cFTVkMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxvJZ4NyOuyOMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_9357b0rpJIdlMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubn7x56QUqtGMeta } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubn7x56QUqtG } from "/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesCZaknKSkn7Meta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesCZaknKSkn7Meta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesCZaknKSkn7Meta || {},
    alias: energy_45green_45practicesCZaknKSkn7Meta?.alias || [],
    redirect: energy_45green_45practicesCZaknKSkn7Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeycdL8TLQ5WUMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeycdL8TLQ5WUMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeycdL8TLQ5WUMeta || {},
    alias: fun_45part_45journeycdL8TLQ5WUMeta?.alias || [],
    redirect: fun_45part_45journeycdL8TLQ5WUMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderIttqkvtSSEMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderIttqkvtSSEMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderIttqkvtSSEMeta || {},
    alias: homesafe_45colorado_45master_45builderIttqkvtSSEMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderIttqkvtSSEMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexojAoldV5TtMeta?.name ?? "about-us",
    path: indexojAoldV5TtMeta?.path ?? "/about-us",
    meta: indexojAoldV5TtMeta || {},
    alias: indexojAoldV5TtMeta?.alias || [],
    redirect: indexojAoldV5TtMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamuNUBZtzEk0Meta?.name ?? "about-us-our-team",
    path: our_45teamuNUBZtzEk0Meta?.path ?? "/about-us/our-team",
    meta: our_45teamuNUBZtzEk0Meta || {},
    alias: our_45teamuNUBZtzEk0Meta?.alias || [],
    redirect: our_45teamuNUBZtzEk0Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderVuPaDR4SDoMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderVuPaDR4SDoMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderVuPaDR4SDoMeta || {},
    alias: preferred_45lenderVuPaDR4SDoMeta?.alias || [],
    redirect: preferred_45lenderVuPaDR4SDoMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcessDkrfcq84QMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcessDkrfcq84QMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcessDkrfcq84QMeta || {},
    alias: realtor_45resourcessDkrfcq84QMeta?.alias || [],
    redirect: realtor_45resourcessDkrfcq84QMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93wFkhlmkBnCMeta?.name ?? "blog-post",
    path: _91post_93wFkhlmkBnCMeta?.path ?? "/blog/:post()",
    meta: _91post_93wFkhlmkBnCMeta || {},
    alias: _91post_93wFkhlmkBnCMeta?.alias || [],
    redirect: _91post_93wFkhlmkBnCMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexfLTZWQJWRAMeta?.name ?? "blog-category-category",
    path: indexfLTZWQJWRAMeta?.path ?? "/blog/category/:category()",
    meta: indexfLTZWQJWRAMeta || {},
    alias: indexfLTZWQJWRAMeta?.alias || [],
    redirect: indexfLTZWQJWRAMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjsGgiI3yDhMeta?.name ?? "blog",
    path: indexjsGgiI3yDhMeta?.path ?? "/blog",
    meta: indexjsGgiI3yDhMeta || {},
    alias: indexjsGgiI3yDhMeta?.alias || [],
    redirect: indexjsGgiI3yDhMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93rxUytysiW5Meta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93rxUytysiW5Meta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93rxUytysiW5Meta || {},
    alias: _91_46_46_46slug_93rxUytysiW5Meta?.alias || [],
    redirect: _91_46_46_46slug_93rxUytysiW5Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexrQMB8pUNBrMeta?.name ?? "campaigns",
    path: indexrQMB8pUNBrMeta?.path ?? "/campaigns",
    meta: indexrQMB8pUNBrMeta || {},
    alias: indexrQMB8pUNBrMeta?.alias || [],
    redirect: indexrQMB8pUNBrMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionZO0RTrz5xbMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionZO0RTrz5xbMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionZO0RTrz5xbMeta || {},
    alias: ask_45a_45questionZO0RTrz5xbMeta?.alias || [],
    redirect: ask_45a_45questionZO0RTrz5xbMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitieseOXzvNGBCTMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitieseOXzvNGBCTMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitieseOXzvNGBCTMeta || {},
    alias: career_45opportunitieseOXzvNGBCTMeta?.alias || [],
    redirect: career_45opportunitieseOXzvNGBCTMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqwyMo3JLwclMeta?.name ?? "contact-us-faq",
    path: faqwyMo3JLwclMeta?.path ?? "/contact-us/faq",
    meta: faqwyMo3JLwclMeta || {},
    alias: faqwyMo3JLwclMeta?.alias || [],
    redirect: faqwyMo3JLwclMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesKOrd1gyOd5Meta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesKOrd1gyOd5Meta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesKOrd1gyOd5Meta || {},
    alias: homeowner_45resourcesKOrd1gyOd5Meta?.alias || [],
    redirect: homeowner_45resourcesKOrd1gyOd5Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexb4lpwYuC38Meta?.name ?? "contact-us",
    path: indexb4lpwYuC38Meta?.path ?? "/contact-us",
    meta: indexb4lpwYuC38Meta || {},
    alias: indexb4lpwYuC38Meta?.alias || [],
    redirect: indexb4lpwYuC38Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationszzV5NsdxWTMeta?.name ?? "contact-us-our-locations",
    path: our_45locationszzV5NsdxWTMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locationszzV5NsdxWTMeta || {},
    alias: our_45locationszzV5NsdxWTMeta?.alias || [],
    redirect: our_45locationszzV5NsdxWTMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersdHCZOKb4g1Meta?.name ?? "current-offers",
    path: current_45offersdHCZOKb4g1Meta?.path ?? "/current-offers",
    meta: current_45offersdHCZOKb4g1Meta || {},
    alias: current_45offersdHCZOKb4g1Meta?.alias || [],
    redirect: current_45offersdHCZOKb4g1Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexmnEv4sirG8Meta?.name ?? "events-event",
    path: indexmnEv4sirG8Meta?.path ?? "/events/:event()",
    meta: indexmnEv4sirG8Meta || {},
    alias: indexmnEv4sirG8Meta?.alias || [],
    redirect: indexmnEv4sirG8Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfAXqD74brHMeta?.name ?? "events",
    path: indexfAXqD74brHMeta?.path ?? "/events",
    meta: indexfAXqD74brHMeta || {},
    alias: indexfAXqD74brHMeta?.alias || [],
    redirect: indexfAXqD74brHMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexBXWJW4b1T3Meta?.name ?? "homes-location-community",
    path: indexBXWJW4b1T3Meta?.path ?? "/homes/:location()/:community()",
    meta: indexBXWJW4b1T3Meta || {},
    alias: indexBXWJW4b1T3Meta?.alias || [],
    redirect: indexBXWJW4b1T3Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: indexd643VYbO80Meta?.name ?? "homes-location-community-models-model",
    path: indexd643VYbO80Meta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: indexd643VYbO80Meta || {},
    alias: indexd643VYbO80Meta?.alias || [],
    redirect: indexd643VYbO80Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexg2aWzJMjX2Meta?.name ?? "homes-location-community-plans-plan",
    path: indexg2aWzJMjX2Meta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexg2aWzJMjX2Meta || {},
    alias: indexg2aWzJMjX2Meta?.alias || [],
    redirect: indexg2aWzJMjX2Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkoBlcWj6tNMeta?.name ?? "homes-location-community-qmi-home",
    path: indexkoBlcWj6tNMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexkoBlcWj6tNMeta || {},
    alias: indexkoBlcWj6tNMeta?.alias || [],
    redirect: indexkoBlcWj6tNMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdBlsJNjD8UMeta?.name ?? "index",
    path: indexdBlsJNjD8UMeta?.path ?? "/",
    meta: indexdBlsJNjD8UMeta || {},
    alias: indexdBlsJNjD8UMeta?.alias || [],
    redirect: indexdBlsJNjD8UMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationbTWxI1pexQMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationbTWxI1pexQMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationbTWxI1pexQMeta || {},
    alias: knolls_45hoa_45informationbTWxI1pexQMeta?.alias || [],
    redirect: knolls_45hoa_45informationbTWxI1pexQMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionsYHB4CYgahMeta?.name ?? "land-acquisition",
    path: land_45acquisitionsYHB4CYgahMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionsYHB4CYgahMeta || {},
    alias: land_45acquisitionsYHB4CYgahMeta?.alias || [],
    redirect: land_45acquisitionsYHB4CYgahMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placePapiIGKbECMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placePapiIGKbECMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placePapiIGKbECMeta || {},
    alias: brents_45placePapiIGKbECMeta?.alias || [],
    redirect: brents_45placePapiIGKbECMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceIL1UTjvtkmMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceIL1UTjvtkmMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceIL1UTjvtkmMeta || {},
    alias: experience_45lower_45maintenanceIL1UTjvtkmMeta?.alias || [],
    redirect: experience_45lower_45maintenanceIL1UTjvtkmMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93cmqbCFNE6nMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93cmqbCFNE6nMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93cmqbCFNE6nMeta || {},
    alias: _91testimonial_93cmqbCFNE6nMeta?.alias || [],
    redirect: _91testimonial_93cmqbCFNE6nMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexHyMTWFA5meMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexHyMTWFA5meMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexHyMTWFA5meMeta || {},
    alias: indexHyMTWFA5meMeta?.alias || [],
    redirect: indexHyMTWFA5meMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuNYsPRYHRSMeta?.name ?? "lifestyle-reviews",
    path: indexuNYsPRYHRSMeta?.path ?? "/lifestyle/reviews",
    meta: indexuNYsPRYHRSMeta || {},
    alias: indexuNYsPRYHRSMeta?.alias || [],
    redirect: indexuNYsPRYHRSMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessdkY9MhbjjWMeta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessdkY9MhbjjWMeta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessdkY9MhbjjWMeta || {},
    alias: whats_45lifefullnessdkY9MhbjjWMeta?.alias || [],
    redirect: whats_45lifefullnessdkY9MhbjjWMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93FTInoVadl0Meta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93FTInoVadl0Meta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93FTInoVadl0Meta || {},
    alias: _91_46_46_46slug_93FTInoVadl0Meta?.alias || [],
    redirect: _91_46_46_46slug_93FTInoVadl0Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesyjL2qF0KVAMeta?.name ?? "my-favorites",
    path: my_45favoritesyjL2qF0KVAMeta?.path ?? "/my-favorites",
    meta: my_45favoritesyjL2qF0KVAMeta || {},
    alias: my_45favoritesyjL2qF0KVAMeta?.alias || [],
    redirect: my_45favoritesyjL2qF0KVAMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchYdJVFEUeTWMeta?.name ?? "new-home-search",
    path: new_45home_45searchYdJVFEUeTWMeta?.path ?? "/new-home-search",
    meta: new_45home_45searchYdJVFEUeTWMeta || {},
    alias: new_45home_45searchYdJVFEUeTWMeta?.alias || [],
    redirect: new_45home_45searchYdJVFEUeTWMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhouseEv9DyKPdWGMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhouseEv9DyKPdWGMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhouseEv9DyKPdWGMeta || {},
    alias: easyhouseEv9DyKPdWGMeta?.alias || [],
    redirect: easyhouseEv9DyKPdWGMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededitionXjKC5bn4XlMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededitionXjKC5bn4XlMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededitionXjKC5bn4XlMeta || {},
    alias: limitededitionXjKC5bn4XlMeta?.alias || [],
    redirect: limitededitionXjKC5bn4XlMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottageF8Yl93YN9hMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottageF8Yl93YN9hMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottageF8Yl93YN9hMeta || {},
    alias: wee_45cottageF8Yl93YN9hMeta?.alias || [],
    redirect: wee_45cottageF8Yl93YN9hMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexZ394JRFFLfMeta?.name ?? "our-homes",
    path: indexZ394JRFFLfMeta?.path ?? "/our-homes",
    meta: indexZ394JRFFLfMeta || {},
    alias: indexZ394JRFFLfMeta?.alias || [],
    redirect: indexZ394JRFFLfMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsMej0sZ9Ox9Meta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsMej0sZ9Ox9Meta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsMej0sZ9Ox9Meta || {},
    alias: our_45neighborhoodsMej0sZ9Ox9Meta?.alias || [],
    redirect: our_45neighborhoodsMej0sZ9Ox9Meta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usuu9GkMKtLpMeta?.name ?? "partner-with-us",
    path: partner_45with_45usuu9GkMKtLpMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45usuu9GkMKtLpMeta || {},
    alias: partner_45with_45usuu9GkMKtLpMeta?.alias || [],
    redirect: partner_45with_45usuu9GkMKtLpMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsgyLR7cFTVkMeta?.name ?? "privacyterms",
    path: privacytermsgyLR7cFTVkMeta?.path ?? "/privacyterms",
    meta: privacytermsgyLR7cFTVkMeta || {},
    alias: privacytermsgyLR7cFTVkMeta?.alias || [],
    redirect: privacytermsgyLR7cFTVkMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxvJZ4NyOuyOMeta?.name ?? "sandbox",
    path: sandboxvJZ4NyOuyOMeta?.path ?? "/sandbox",
    meta: sandboxvJZ4NyOuyOMeta || {},
    alias: sandboxvJZ4NyOuyOMeta?.alias || [],
    redirect: sandboxvJZ4NyOuyOMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_9357b0rpJIdlMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_9357b0rpJIdlMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_9357b0rpJIdlMeta || {},
    alias: _91_46_46_46slug_9357b0rpJIdlMeta?.alias || [],
    redirect: _91_46_46_46slug_9357b0rpJIdlMeta?.redirect,
    component: () => import("/codebuild/output/src3487344809/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/build-with-us/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/career-opportunities/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/email-signup/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/get-updates/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/how-to-video-library/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/lifestyle/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/login/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/lost-password/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/maintenance-items/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/organism-page/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/register/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/reset-password/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/style-guide/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  },
  {
    name: component_45stubn7x56QUqtGMeta?.name ?? undefined,
    path: component_45stubn7x56QUqtGMeta?.path ?? "/warranty-service-request/",
    meta: component_45stubn7x56QUqtGMeta || {},
    alias: component_45stubn7x56QUqtGMeta?.alias || [],
    redirect: component_45stubn7x56QUqtGMeta?.redirect,
    component: component_45stubn7x56QUqtG
  }
]